import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
        <path d="M2611 3710 c22 -94 -9 -192 -78 -249 -51 -42 -120 -67 -303 -107
-262 -58 -352 -96 -402 -172 -27 -42 -22 -44 32 -17 72 36 162 56 205 44 52
-15 68 -46 63 -122 -4 -61 -24 -116 -112 -314 -102 -228 -114 -460 -31 -623
14 -28 27 -50 29 -50 2 0 -1 23 -5 52 -16 103 20 253 93 388 60 110 104 173
266 374 203 254 252 331 298 466 37 110 16 271 -46 350 l-19 25 10 -45z"/>
<path d="M2265 3713 c-109 -29 -131 -189 -35 -247 35 -20 102 -21 139 -2 83
44 88 179 8 230 -26 17 -83 27 -112 19z"/>
<path d="M2725 3523 c0 -54 -4 -117 -9 -139 -8 -38 -6 -42 16 -47 13 -4 37
-20 53 -37 27 -28 30 -38 30 -91 0 -57 -2 -63 -36 -94 -40 -36 -105 -55 -189
-55 -47 0 -49 -1 -98 -62 -27 -35 -72 -91 -100 -125 l-52 -61 212 -4 c235 -4
263 -10 311 -67 70 -83 29 -200 -81 -231 -20 -5 -135 -10 -254 -10 l-218 0 0
137 0 137 -47 -64 c-115 -158 -186 -309 -208 -438 l-7 -43 393 3 c427 5 452 7
567 65 63 32 136 107 165 171 17 38 22 66 22 147 0 90 -3 106 -28 157 -32 65
-88 118 -164 156 -29 14 -53 29 -53 33 0 3 18 18 39 32 156 104 176 370 37
508 -62 62 -194 119 -276 119 l-25 0 0 -97z"/>
<path d="M2951 1855 c-4 -5 -24 -63 -45 -129 -21 -65 -40 -125 -43 -133 -4
-11 4 -14 38 -11 37 3 45 7 47 26 3 21 8 23 55 20 45 -3 52 -6 55 -25 3 -19
10 -23 43 -23 21 0 39 3 39 6 0 3 -19 66 -42 140 -35 114 -45 134 -62 135 -12
0 -34 2 -49 3 -15 1 -32 -3 -36 -9z m79 -147 c0 -5 -13 -8 -30 -8 -19 0 -28 4
-25 13 2 6 9 32 16 57 l12 45 13 -50 c8 -27 14 -53 14 -57z"/>
<path d="M1300 1720 l0 -140 64 0 c73 0 122 14 136 40 17 32 12 78 -11 94
l-21 14 21 21 c29 29 27 65 -5 90 -22 17 -41 21 -105 21 l-79 0 0 -140z m114
84 c22 -9 20 -31 -4 -44 -26 -14 -40 -5 -40 26 0 24 13 30 44 18z m16 -119
c17 -20 -3 -45 -36 -45 -20 0 -24 5 -24 30 0 25 4 30 24 30 13 0 29 -7 36 -15z"/>
<path d="M1630 1720 l0 -140 40 0 40 0 0 140 0 140 -40 0 -40 0 0 -140z"/>
<path d="M1840 1720 l0 -140 90 0 90 0 0 29 c0 29 -2 30 -52 33 l-53 3 -3 108
-3 107 -34 0 -35 0 0 -140z"/>
<path d="M2140 1720 l0 -140 90 0 90 0 0 30 c0 30 -1 30 -55 30 l-55 0 0 110
0 110 -35 0 -35 0 0 -140z"/>
<path d="M2440 1720 l0 -140 30 0 30 0 1 98 c1 95 1 96 14 57 8 -22 21 -66 30
-98 17 -58 17 -58 53 -55 l35 3 28 95 27 95 1 -97 1 -98 35 0 35 0 0 141 0
140 -52 -3 -52 -3 -23 -80 c-13 -44 -26 -87 -29 -95 -7 -17 -9 -12 -44 108
l-22 72 -49 0 -49 0 0 -140z"/>
<path d="M3222 1833 c2 -23 8 -29 36 -31 l32 -3 0 -110 0 -109 40 0 40 0 0
110 0 110 35 0 c32 0 35 2 35 30 l0 30 -111 0 -110 0 3 -27z"/>
<path d="M3550 1720 l0 -140 80 0 80 0 0 30 c0 29 -2 30 -45 30 -43 0 -45 1
-45 30 0 29 2 30 45 30 43 0 45 1 45 30 0 29 -2 30 -45 30 -38 0 -45 3 -45 20
0 17 7 20 45 20 43 0 45 1 45 30 l0 30 -80 0 -80 0 0 -140z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
